<template>
    <div class="col-12 vh-100">
        <div class="row">
            <div class="d-flex align-items-end flex-column vh-100 p-0 conversation">
                <div class="chatbot-header-outer-padding">
                    <div class="chatbot-header-inner-bg">
                        <div class="whatsapp-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img v-if="this.logoname != null && this.logoname != ''"
                                            :src="this.clientlogo" class="profile-header-avatar rounded-circle"
                                            alt="" width="46" height="46" />
                                        <img v-else src="/assets/images/login-icon.png"
                                            class="profile-header-avatar rounded-circle" alt="" width="46"
                                            height="46" />
                                    </div>
                                    <div class="flex-grow-1" style="margin-left: 15px;">
                                        <div class="whatsapp-header-name text-capitalize">{{ this.clientname ?
                                            this.clientname :
                                            "N/A" }}<img
                                                src="/assets/images/whatsapp-icon/material-symbols_verified-rounded.png"
                                                class="ms-1" alt="" width="17" height="17"
                                                style="vertical-align: top;" /></div>
                                        <div class="whatsapp-header-number">online</div>
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="chatbox-right-outer">
                    <div class="chatbox-inner-content" id="chatcontainer">
                        <div class="p-dialog-content">
                            <div class="conversation-container" v-if="!showloadermodal">
                                <div class="text-center chatbot-date-header" style="display: inline-block; width: 100%;" v-show="loadhistoryflag && this.totalrowcount > this.rowcount">
                                    <button type="button" :disabled="paginationloder" @click="loadpagination()" class="btn loadmore-history-btn">
                                        <span v-if="!paginationloder">Load more History</span>
                                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="paginationloder">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                                <div 
                                    style="display: inline-block; width: 100%;" v-for="(value, key ) in whatsAppChatHistory" :key="key">
                                    <div class="text-center chatbot-date-header">
                                        <div class="date-outer-header">
                                            <span class="date-inner-header"><span class="date-label">{{ format_date(key) }}</span></span>
                                        </div>
                                    </div>
                                    <!-- incoming msg -->
                                    
                                    <div v-for="(msg, index) in value" :key="msg" class="message-outer-body">
                                        <div v-if="msg.lo3 == 1" style="width: 100%; display: inline-block; position: relative;">
                                            <div class="message sent">
                                                <div class="message-send-padding">
                                                    <div>
                                                        <div v-if="msg.lo4" class="message-text-send">
                                                            <span v-html="decodeHtml(msg.lo4)"></span>
                                                        </div>
                                                    </div>
                                                    <a class="whatsapp-image-cursor" v-if="msg.lo6"><img
                                                            :src="msg.lo6" style="vertical-align: middle"
                                                            class="whatsapp-Sent-image p-mt-1" /></a>
                                                </div>
                                            </div>
                                            <div class="metadata-send"><span class="time">{{
                                        format_timestamptime(msg.lo2) }}</span></div>
                                        </div>
                                        
                                        <!-- Outgoing Msg -->
                                        <div v-if="msg.lo3 == 2">
                                            <div class="message received">
                                                <div class="">
                                                    <div v-if="msg.lo16" class="">
                                                        <img :src="msg.lo16"
                                                            style="vertical-align: middle; width: 100%;border-radius: 0px 9.41px 0px 0px;"
                                                            class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                    </div>
                                                    <div v-if="msg.lo4" class="message-text-received message-received-padding">
                                                        <span v-html="decodeHtml(msg.lo4)"></span>
                                                    </div>
                                                    <div v-if="msg.lo29 == 20 && (index+1) == value.length">
                                                        <div class="message-received-padding py-0">
                                                            <div class="custom-form-group">
                                                                
                                                                <div v-for="(value, key ) in botList" :key="key"> 
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="botId" name="voter-bot-option"
                                                                            :id="value.label" autocomplete="off" :value="value.value" @change="displayInput()" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="Leisuretrip">{{ value.label }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="button"
                                                            class="whatsappbtnreply btn-border-radius-custom"
                                                            :disabled="this.botbtnloader || this.botId == ''"
                                                            @click="sendBotId(msg.lo1)">Submit</button>
                                                    </div>
                                                    <!--  || this.selectedOptionLabel -->
                                                    <a class="whatsapp-image-cursor" v-if="msg.lo6"><img
                                                            :src="msg.lo6" style="vertical-align: middle"
                                                            class="whatsapp-Sent-image p-mt-1" /></a>
                                                </div>
                                                <div >
                                                    <button type="button" class="whatsappbtnreply" v-if="msg.lo17"
                                                        @click="SubmitBtnValue(msg.lo17, msg.lo1)"
                                                        :disabled="((index+1) != value.length) || (this.btnclickloader)" :class="[msg.last_clicked_btn == msg.lo17 ? 'orange-btn-disabled-color' : '', msg.lo18 == '' ? 'btn-border-radius-custom' : '']">
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-if="(index+1) != value.length || this.btnclickloader" class="me-2"
                                                            style="vertical-align: sub;" width="16.65"
                                                            height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-else class="me-2" style="vertical-align: sub;"
                                                            width="16.65" height="16.65" />
                                                        {{ msg.lo17
                                                        }}</button>
                                                    <button type="button" class="whatsappbtnreply" v-if="msg.lo18"
                                                        @click="SubmitBtnValue(msg.lo18, msg.lo1)"
                                                        :disabled="(index+1) != value.length || this.btnclickloader" :class="[msg.last_clicked_btn == msg.lo18 ? 'orange-btn-disabled-color' : '', msg.lo19 == '' ? 'btn-border-radius-custom' : '']">
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-if="(index+1) != value.length || this.btnclickloader" class="me-2"
                                                            style="vertical-align: sub;" width="16.65"
                                                            height="16.65" />
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-else class="me-2" style="vertical-align: sub;"
                                                            width="16.65" height="16.65" />{{ msg.lo18
                                                        }}</button>
                                                    <button type="button" class="whatsappbtnreply" v-if="msg.lo19"
                                                        @click="SubmitBtnValue(msg.lo19, msg.lo1)"
                                                        :disabled="(index+1) != value.length || this.btnclickloader" :class="[msg.last_clicked_btn == msg.lo19 ? 'orange-btn-disabled-color' : '', msg.lo20 == '' ? 'btn-border-radius-custom' : '']">
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-if="(index+1) != value.length || this.btnclickloader" class="me-2"
                                                            style="vertical-align: sub;" width="16.65"
                                                            height="16.65" />
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-else class="me-2" style="vertical-align: sub;"
                                                            width="16.65" height="16.65" />{{ msg.lo19
                                                        }}</button>
                                                    <button type="button" class="whatsappbtnreply" v-if="msg.lo20"
                                                        @click="SubmitBtnValue(msg.lo20, msg.lo1)"
                                                        :disabled="(index+1) != value.length || this.btnclickloader" :class="[msg.last_clicked_btn == msg.lo20 ? 'orange-btn-disabled-color' : '', msg.lo21 == '' ? 'btn-border-radius-custom' : '']">
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-if="(index+1) != value.length || this.btnclickloader" class="me-2"
                                                            style="vertical-align: sub;" width="16.65"
                                                            height="16.65" />
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-else class="me-2" style="vertical-align: sub;"
                                                            width="16.65" height="16.65" />{{ msg.lo20
                                                        }}</button>
                                                    <button type="button" class="whatsappbtnreply btn-border-radius-custom" v-if="msg.lo21"
                                                        @click="SubmitBtnValue(msg.lo21, msg.lo1)"
                                                        :disabled="(index+1) != value.length || this.btnclickloader" :class="msg.last_clicked_btn == msg.lo21 ? 'orange-btn-disabled-color' : ''">
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-if="(index+1) != value.length || this.btnclickloader" class="me-2"
                                                            style="vertical-align: sub;" width="16.65"
                                                            height="16.65" />
                                                        <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                            v-else class="me-2" style="vertical-align: sub;"
                                                            width="16.65" height="16.65" />{{ msg.lo21
                                                        }}</button>
                                                </div>
                                            </div>
                                            <div class="metadata-received"><span class="time">{{
                                        format_timestamptime(msg.lo2) }}</span></div>
                                        </div>
                                    </div>
                                    <div class="message-outer-body" v-if="localchatdata == 1">
                                        <div  style="width: 100%; display: inline-block; position: relative;">
                                            <div class="message sent">
                                                <div class="message-send-padding">
                                                    <div v-if="localchatdata == 1">
                                                        <div class="message-text-send">{{ this.localchatbtnlabel }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showloadermodal">
                                <div class="custom-modal-spinner-loader">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Old validation 0 = stop chatbot, 1 = text,2=button,3 = number type,4= aadhar number,5=OTP,6=Voter Card Number,7=image,8=contact,9=location, 10 = video,11= Mobile Number,12=Email -->
                <!-- New validation 0 = stop chatbot, 1=button,2= Link Button,3= Dynamic Link Button,4=Mobile Number,5= Email,6=Aadhar Number,7=Voter Id,8=Pancard,9=Driving Licence,10=Vehicle Number,11= image,12=video,13=OTP,14=Text,15= PDF or Word file,16=contact,17=location -->
                <div class="modal-footer p-0 w-100" style="border-top: 0px;">
                    <div class="whatsapp-textbtn-outer">
                        <div class="conversation-compose">
                            <input type="text" v-on:keyup.enter="sendInputMessage()"
                                class="form-control input-msg custom-chat-group-textarea"
                                :disabled="isDisabled"
                                :maxlength="this.input_validation == 6 ? '12' : this.input_validation == 13 ? '6' : this.input_validation == 4 ? '10' : this.input_validation == 7 ? '10' : '30'"
                                :class="this.input_validation == 7 ? 'text-uppercase' : ''" v-model="chatInput" rows="1"
                                placeholder="Message" v-on:="this.input_validation == 6 ? ({ keypress: onlyNumber, paste: onPasteAadhar }) : (this.input_validation == 13 ? { keypress: onlyNumber, paste: onPasteOtp } :
                                            (this.input_validation == 4 ? { keypress: onlyNumber, paste: onPasteMobile } : ''))"
                                 />
                                 <img src="/assets/images/icon/input-chat-loader.gif" v-if="chatbotloaderflag" class="input-chat-loader" width="80" height="80" />
                             
                            <div class="send-btn-outer">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1" style="padding-left: 18px;">
                                        <button class="send" @click="sendInputMessage()"
                                            :disabled="isBtnDisabled || this.chatInput.length < 1">
                                            <img src="/assets/images/whatsapp-icon/majesticons_share-line.png"
                                                                class="send-icon-btn" 
                                                                width="24" height="24" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import ApiService from "../service/ApiService";
import { useRoute } from "vue-router";
export default {
    data() {
        return {
            whatsappprofile: '',
            whatsappname: "BJP Goa",
            whatsAppChatHistory: [],
            showloadermodal: false,
            file1: null,
            uploadfilename: null,
            chatInput: "",
            routeParam: '',
            input_validation: '',
            btnloader: false,
            btnclickloader: false,
            receivedfilterarray : [],
            sendfilterarray: [],
            localstoragereceviedmsg: [],
            localstoragesendmsg: [],
            localchatdata: 0,
            localchatbtnlabel: '',
            pageload_no: 0,
            paginationloder: false,
            loadhistoryflag: false,
            firstpagecount: 5,
            pageloadcount: 0,
            rowcount: 0,
            totalrowcount: 0,
            webbotfk: '',
            chatbotloaderflag: false,
            botbtnloader: false,
            inputValidationVoterCard : 0,
            isDisabled: false,
            isBtnDisabled: false,
            selectedOptionLabel: false,
            botId:'',
            defaultBotList: [
                { label: 'Health Care', value: 1 },
                { label: 'Education Support', value: 2 },
                { label: 'Employment Guidance', value: 3 },
                { label: 'Police Assistance', value: 4 },
                { label: 'Documentation Support', value: 5 },
                { label: 'Drinking Water Support', value: 6 },
                { label: 'Electricity Dept Support', value: 7 },
            ],
            client_info: '',
            clientname: '',
            clientlogo: '',
            bucket_name: '',
            logoname: '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    beforeUnmount() {
        clearInterval(this.timer3);
    },
    mounted() {
        const route = useRoute();
        var routeParamquery = route.query.value;
        this.routeParam = routeParamquery;
        // this.timer3 = window.setInterval(() => {
        //     this.getVoterWebBotHistoryreload();
        // }, 2000);
        localStorage.setItem("chatbuttonlabel", JSON.stringify(''));
        this.webbotfk = localStorage.webbotid;
        this.client_info = JSON.parse(localStorage.webbot_info);
        this.clientname = this.client_info.client_info.maa65;
        this.bucket_name = this.client_info.master_bucket_name;
        this.logoname = this.client_info.client_info.maa6;
        this.clientlogo = "https://storage.googleapis.com/" + this.bucket_name + "/Client/Logo/" + this.logoname;
        this.getAllBots();
        this.getVoterWebBotHistory();
    },
    computed: {
        filterBotReceived () {
            var filteredrec = [];
            for (var i = 0; i < this.whatsAppChatHistory.length; i++) {
                if (this.whatsAppChatHistory[i].lo3 == 2) {
                    filteredrec.push(this.whatsAppChatHistory[i]);
                }
            }
            return filteredrec;
        },
        filterBotSend () {
            var filteredsend = [];
            for (var i = 0; i < this.whatsAppChatHistory.length; i++) {
                if (this.whatsAppChatHistory[i].lo3 == 1) {
                    filteredsend.push(this.whatsAppChatHistory[i]);
                }
            }
            return filteredsend;
        }
    },
    methods: {
        loadpagination() {
            this.paginationloder = true;
            this.pageload_no = this.pageload_no + 1;
            this.ApiService.getVoterWebBotHistory({ ak1: this.routeParam, page_no: this.pageload_no }).then((data) => {
                if (data.success == true) {
                    this.paginationloder = false;
                    this.whatsAppChatHistory = data.data;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    if(this.whatsAppChatHistory) {
                        this.receivedfilterarray = this.filterBotReceived;
                        localStorage.setItem("receivedfilterarray", JSON.stringify(this.receivedfilterarray));
                        this.sendfilterarray = this.filterBotSend;
                        localStorage.setItem("sendfilterarray", JSON.stringify(this.sendfilterarray));
                        if(localStorage.receivedfilterarray != null) {
                            this.localstoragereceviedmsg = localStorage.receivedfilterarray;
                        }
                        if(localStorage.sendfilterarray != null) {
                            this.localstoragesendmsg = localStorage.sendfilterarray;
                        }
                    }
                    this.input_validation = data.input_validation;
                    this.inputValidationVoterCard = data.inputValidationVoterCard;
                    this.isDisabled = false;
                    this.isBtnDisabled = false;
                    if (this.input_validation != this.inputValidationVoterCard && this.input_validation == 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else if (this.input_validation == 1 && this.inputValidationVoterCard != 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else{
                        this.isDisabled = false;
                        this.isBtnDisabled = false;
                    }
                } else {
                    this.input_validation = 0;
                    this.inputValidationVoterCard = 0;
                    this.whatsAppChatHistory = null;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.paginationloder = false;
                }
            });
        },
        getVoterWebBotHistory() {
            this.showloadermodal = true;
            this.chatInput = "";
            this.file1 = "";
            this.uploadfilename = "";
            this.localchatdata = 0;
            this.ApiService.getVoterWebBotHistory({ ak1: this.routeParam, botUrl: this.webbotfk }).then((data) => {
                if (data.success == true) {
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = data.data;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    if(this.whatsAppChatHistory) {
                        this.receivedfilterarray = this.filterBotReceived;
                        localStorage.setItem("receivedfilterarray", JSON.stringify(this.receivedfilterarray));
                        this.sendfilterarray = this.filterBotSend;
                        localStorage.setItem("sendfilterarray", JSON.stringify(this.sendfilterarray));
                        if(localStorage.receivedfilterarray != null) {
                            this.localstoragereceviedmsg = localStorage.receivedfilterarray;
                        }
                        if(localStorage.sendfilterarray != null) {
                            this.localstoragesendmsg = localStorage.sendfilterarray;
                        }
                    }
                    this.scrollmessage();
                    this.input_validation = data.input_validation;
                    this.inputValidationVoterCard = data.inputValidationVoterCard;

                    this.isDisabled = false;
                    this.isBtnDisabled = false;
                    if (this.input_validation != this.inputValidationVoterCard && this.input_validation == 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else if (this.input_validation == 1 && this.inputValidationVoterCard != 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else{
                        this.isDisabled = false;
                        this.isBtnDisabled = false;
                    }
                } else {
                    this.input_validation = 0;
                    this.inputValidationVoterCard = 0;
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = null;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.scrollmessage();
                }
            });
        },
        sendInputMessage() {
            if (this.chatInput) {
                this.btnloader = true;
                this.chatbotloaderflag = true;
                const formData = new FormData();
                formData.append("lo1", "");
                formData.append("MediaType", "text");
                formData.append("voterID", this.routeParam);
                formData.append("CONTEXT_MESSAGE_ID", "");
                formData.append("ButtonLabel", "");
                console.log(this.input_validation);
                if (this.input_validation == 7) {
                    formData.append("Text", this.chatInput.toUpperCase());
                } else {
                    formData.append("Text", this.chatInput);
                }
                if (this.file1) {
                    formData.append("MediaData", this.file1);
                }
                if(localStorage.chatbuttonlabel) {
                    if (this.input_validation == 7) {
                        localStorage.setItem("chatbuttonlabel", JSON.stringify(this.chatInput.toUpperCase()));
                    }else{
                        localStorage.setItem("chatbuttonlabel", JSON.stringify(this.chatInput));
                    }
                    
                    this.localchatdata = 1;
                    this.localchatbtnlabel = JSON.parse(localStorage.chatbuttonlabel);
                    this.scrollmessage();
                }
                if (this.chatInput != '' && this.file1 != null) {
                    this.chatInput = "";
                    this.isDisabled = !this.isDisabled;
                    
                    this.ApiService.webincomingmsg(formData).then(() => {
                        this.chatInput = "";
                        this.file1 = "";
                        this.uploadfilename = "";
                        this.input_validation = '';
                        this.getAllBots();
                        this.getVoterWebBotHistorysubmit();
                    });
                } else {
                    this.btnloader = false;
                }
            }
        },
        SubmitBtnValue(label, lo1) {
            this.chatbotloaderflag = true;
            this.btnclickloader = true;
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "button");
            formData.append("MediaData", "");
            formData.append("Text", "");
            formData.append("voterID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", label);
            if(localStorage.chatbuttonlabel) {
                localStorage.setItem("chatbuttonlabel", JSON.stringify(label));
                this.localchatdata = 1;
                this.localchatbtnlabel = JSON.parse(localStorage.chatbuttonlabel);
                this.scrollmessage();
            }
            this.ApiService.webincomingmsg(formData).then(() => {
                this.btnclickloader = false;
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.getAllBots();
                this.getVoterWebBotBtnubmit();
            });
        },
        getVoterWebBotBtnubmit() {
            this.ApiService.getVoterWebBotHistory({ ak1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.data;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.chatbotloaderflag = false;
                    this.localchatdata = 0;
                    localStorage.setItem("chatbuttonlabel", JSON.stringify(''));
                    this.scrollmessage();
                    this.input_validation = data.input_validation;
                    this.inputValidationVoterCard = data.inputValidationVoterCard;
                    this.isDisabled = false;
                    this.isBtnDisabled = false;
                    if (this.input_validation != this.inputValidationVoterCard && this.input_validation == 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else if (this.input_validation == 1 && this.inputValidationVoterCard != 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else{
                        this.isDisabled = false;
                        this.isBtnDisabled = false;
                    }
                } else {
                    this.input_validation = 0;
                    this.inputValidationVoterCard = 0;
                    this.whatsAppChatHistory = null;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.chatbotloaderflag = false;
                }
            });
        },
        getVoterWebBotHistoryreload() {
            this.ApiService.getVoterWebBotHistory({ ak1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.data;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.input_validation = data.input_validation;
                    this.inputValidationVoterCard = data.inputValidationVoterCard;
                    this.isDisabled = false;
                    this.isBtnDisabled = false;
                    if (this.input_validation != this.inputValidationVoterCard && this.input_validation == 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else if (this.input_validation == 1 && this.inputValidationVoterCard != 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else{
                        this.isDisabled = false;
                        this.isBtnDisabled = false;
                    }
                } else {
                    this.input_validation = 0;
                    this.inputValidationVoterCard = 0;
                    this.whatsAppChatHistory = null;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                }
            });
        },
        getVoterWebBotHistorysubmit() {
            this.ApiService.getVoterWebBotHistory({ ak1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.data;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.scrollmessage();
                    this.btnloader = false;
                    this.btnclickloader = false;
                    this.chatbotloaderflag = false;
                    this.localchatdata = 0;
                    localStorage.setItem("chatbuttonlabel", JSON.stringify(''));
                    this.input_validation = data.input_validation;
                    this.inputValidationVoterCard = data.inputValidationVoterCard;
                    this.isDisabled = false;
                    this.isBtnDisabled = false;
                    if (this.input_validation != this.inputValidationVoterCard && this.input_validation == 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else if (this.input_validation == 1 && this.inputValidationVoterCard != 1) {
                        this.isDisabled = true;
                        this.isBtnDisabled = true;
                    }else{
                        this.isDisabled = false;
                        this.isBtnDisabled = false;
                    }
                } else {
                    this.input_validation = 0;
                    this.inputValidationVoterCard = 0;
                    this.whatsAppChatHistory = null;
                    this.rowcount = data.row_count;
                    this.totalrowcount = data.total_count;
                    this.btnloader = false;
                    this.btnclickloader = false;
                    this.chatbotloaderflag = false;
                }
            });
        },
        sendBotId(lo1) {
            this.btnclickloader = true;
            this.botbtnloader = true;
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "text");
            formData.append("MediaData", "");
            var ele = document.getElementsByName('voter-bot-option');
            let selectedOption = '';
 
            for (let i = 0; i < ele.length; i++) {
                if (ele[i].checked)
                selectedOption = ele[i].id;
            }

            formData.append("Text", selectedOption);
            formData.append("voterID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", "");
            formData.append("bot_fk", this.botId);

            if(localStorage.chatbuttonlabel) {
                localStorage.setItem("chatbuttonlabel", JSON.stringify(selectedOption));
                this.localchatdata = 1;
                this.localchatbtnlabel = JSON.parse(localStorage.chatbuttonlabel);
                this.scrollmessage();
            }
            formData.append("botUrl", this.webbotfk);
            this.ApiService.webincomingmsg(formData).then(() => {
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.botId = '';
                this.getAllBots();
                this.getVoterWebBotHistorysubmit();
                this.botbtnloader = false;
            });
        },
        toggleDisabled() {
            this.isDisabled = !this.isDisabled;
        },
        validateAttachment(e) {
            var fileData = e.target.files[0];
            this.uploadfilename = fileData.name;
            if (this.$refs.uploadfile.files.length > 3) {
                this.$toast.open({
                    message: "Maximum 3 files are allowed",
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                });
            } else {
                let len = this.$refs.uploadfile.files.length;
                if (len === 1) {
                    this.file1 = this.$refs.uploadfile.files[0];
                } else if (len === 2) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                } else if (len === 3) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                    this.file3 = this.$refs.uploadfile.files[2];
                }
            }
        },
        format_timestamptime(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("hh:mm a");
            } else {
                return 'N/A';
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD MMMM YYYY");
            }
        },
        scrollmessage() {
            setTimeout(() => {
                var messageBody = document.querySelector('#chatcontainer');
                if (messageBody != null) {
                    messageBody.scrollTop = messageBody.scrollHeight;
                }
                this.loadhistoryflag = true;
            }, 1000);
        },
        replacebrake(html) {
            if (html) {
                const doc = html;
                return doc.replace(/(\r)*\n/g, '<br>');
            }
        },
        decodeHtml(html) {
            if (html) {
                const doc = html;
                return doc.replace(/(\r)*\n/g, '<br>');
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        isLetterWithOutSpaceNumber($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9]+$/.test(char)) return true;
            else $event.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(mobvalue)) {
                let value = mobvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 10);
                return true;
            }
            else event.preventDefault();
        },
        onPasteOtp(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 4);
                return true;
            }
            else event.preventDefault();
        },
        onPasteAadhar(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 12);
                return true;
            }
            else event.preventDefault();
        },
        onPasteCommon(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (otpvalue) {
                this.chatInput = otpvalue.slice(0, 30);
                return true;
            }
            else event.preventDefault();
        },
        getAllBots() {
            
            this.botList = this.defaultBotList;
            // this.ApiService.getAllBots({ak1: this.routeParam}).then((data) => {
            //     if (data.status == 200) {
            //         this.botList = data.data;
            //         console.log(this.botList);
            //     } else {
            //         this.botList = null;
            //     }
            // });
        },
        displayInput(){
            console.log(1);
            this.selectedOptionLabel = true;
        },
    },
}
</script>
<style scoped>
/* new chatbot css start here */
.chatbot-header-outer-padding {
    font-family: 'Inter-Regular';
    background-color: #ffffff;
    width: 100%;
}

.whatsapp-header {
    padding: 13px 20.5px;
    gap: 70px;
    border-radius: 12px;
    box-shadow: 0px 1px 5.6px 0px #00000026;
    background: #FFFFFF;
    height: 72px;
}

.whatsapp-header-name {
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #000000;
    margin-bottom: 6px;
    height: 21px;
    padding-top: 4px;
}

.whatsapp-header-number {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #C4C4D5;
    height: 15px;
}

.chatbot-header-inner-bg {
    padding: 21px 15px 19px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}

.whatsapp-header-content {
    background: #F1F2F5;
    padding: 6.02px 8.69px 6.02px 8.69px;
    gap: 4.68px;
    border-radius: 7.35px;
    opacity: 0px;
}

.whatsapp-header-content .whatsapp-header-content-label {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    /* text-align: center; */
    color: #8D93A5;
}
/* .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    position: relative;
    margin: 8px 0;
    max-width: 20%;
    word-wrap: break-word;
} */
.message.received {
    background: #4849A1;
    border-radius: 0px 10.52px 10.52px 10.52px;
}


.message .message-received-padding {
    padding: 13.14px 17.29px 27.17px 17.77px;
}
.message .message-send-padding {
    padding: 17.19px 16px 16.81px 12px;
}

.message .message-text-received {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
    word-break: break-word;
}

.message .message-text-send {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}



.whatsappbtnreply {
    width: 100%;
    border: 0;
    /* margin-bottom: 6px; */
    background-color: transparent;
    font-family: 'Inter-Regular';
    border-radius: 0;
    padding: 14.2px 16.65px;
    border-top: 0.88px solid rgba(214, 216, 216, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: #FFFFFF;
}

.whatsappbtnreply:disabled {
    background: #ced4da;
}
.orange-btn-disabled-color {
    background-color: #F95959 !important;
}

.metadata-received .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: left;
    color: #667781;

}
.metadata-received {
    margin-top: 9.64px;
    line-height: 12.73px;
}
.metadata-send {
    margin-top: 8.54px;
    float: right;
    width: 100%;
    text-align: right;
}

.metadata-send .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: right;
    color: #667781;
}

.message-outer-body {
    display: inline-block;
    width: 100%;
    clear: both;
    position: relative;
    margin-bottom: 22px;
}

.alrt-msg-icon {
    width: 20.3px;
    height: 20.3px;
    margin: 4px;
}

.conversation {
    height: calc(100% - 12px);
    position: relative;
    background: url("/assets/images/whatsapp-icon/bg-chatbot.png") no-repeat;
    z-index: 0;
    background-size: contain;
}
.whatsapp-textbtn-outer {
    padding: 20.5px 19.5px;
    width: 100%;
    margin: 0;
}
.conversation-compose .send {
    background: #F95959;
    border-radius: 20px;
    position: relative;
    width: 66px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 8px 21px;
}
.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 40px;
    width: 100%;
    z-index: 2;
}
.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    color: #3D3D3D;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    padding: 12.5px 13px;
    resize: none;
    border-radius: 21px;
    font-weight: 500;
    line-height: 14.52px;
    border: 1px solid #DBDEE7;
    height: 40px;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    text-align: start;
    text-transform: capitalize;
    line-height: 14.52px;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    box-shadow: none;
}
.chatbot-date-header {
    margin-bottom: 35px;
}
.chatbot-date-header .date-outer-header {
    text-align: center;
    position: relative;
    border-bottom: 1px solid rgb(240, 240, 246);
}
.chatbot-date-header .date-inner-header {
    padding: 0px 15px;
    position: relative;
    top: 11px;
    background: #fff;
}
.chatbot-date-header .date-label {
    color: #8D93A5;
    font-family: 'Inter-Regular';
    font-size: 12px;
    line-height: 14.52px;
    padding: 6.79px 18.1px;
    border-radius: 12.06px;
    background: #F1F2F5;
    font-weight: 400;
    position: relative;
}
.conversation-container {
    height: calc(100% - 5px);
    padding: 0 25px;
}
.message.sent {
    background: #F0F0F6;
    border-radius: 9.32px 9.32px 0px 9.32px;
    float: right;
    width: auto;
}
.form-control.input-msg.custom-chat-group-textarea:disabled {
    background: #f7f7f7;
    border: 0;
}
.form-control.input-msg.custom-chat-group-textarea:disabled::placeholder {
    color: #fff;
}

.conversation-compose .send:disabled {
    background: #f7f7f7;
}
.messagereplyouter {
    background: #e1f1d2;
    padding: 8px;
    margin-bottom: 5px;
    box-shadow: -3px 0px 0px 0px rgb(72 73 161 / 75%);
    border-radius: 3px;
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}
.chat-alert-message-header-fixed {
    padding: 21px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}
/* #old css start here */

.chatbox-right-outer {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    padding: 0;
    height: 100%;
    overflow: auto;
}
.chatbox-right-outer .chatbox-inner-content {
    height: calc(100% - 0px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    padding: 2px 0;
    overflow: auto;
    position: relative;
}
.p-dialog-content {
    padding: 0;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}
.conversation-container::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #c54d4d !important;
    /* background: #7f7777 !important; */
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    padding: 0;
    position: relative;
    outline: none;
}


/* .conversation-compose .send:focus {
    opacity: 0.8;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}
.btn-border-radius-custom {
    border-radius: 0px 0px 10.52px 10.52px;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .message.received, .message.sent, .metadata-received {
        max-width: 95%;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 320px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 90%;
    }
}
@media (min-width: 480px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 95%;
    }
}
@media (min-width: 768px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 98%;
    }
}
@media (min-width: 1024px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 98%;
    }
}
@media (min-width: 1220px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 98%;
    }
}
@media (min-width: 1440px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 98%;
    }
}
/* @media (min-width: 767px) and (max-width: 1020px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 98%;
    }
}
@media (min-width: 390px) and (max-width: 767px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 93%;
    }
}
@media (min-width: 320px) and (max-width: 389px) {
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 86%;
    }
} */
.loadmore-history-btn {
    font-family: 'Inter-Regular';
    padding: 6.79px 18.1px;
    border-radius: 20px;
    background: #F95959;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
}
.conversation-compose .input-chat-loader {
    position: absolute;
    bottom: 0px;
    left: 30%;
}
.custom-chat-radio-form {
    min-height: 2.5rem;
    align-items: center;
    display: flex;
}
.custom-chat-radio-form .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 10px;
}
.custom-chat-radio-form .form-check-label {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #ffffff;
}
</style>